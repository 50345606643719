<template>
<div class="mx-auto header_principal">
    <header>
        <div class="header shadow-md">
            <div class="flex justify-between items-center">
                <img src="@/assets/back-arrow.svg" alt="Ir atrás" @click="goBack()" class="back-arrow-menu-mobile cursor-pointer" />

                <div class="box-logo">
                    <img src="@/assets/back-arrow.svg" alt="Ir atrás" @click="goBack()" class="back-arrow-menu-desktop cursor-pointer" />
                    <router-link to="/cuenta/principal">
                        <img src="@/assets/logo-heaedr.png" class="header-img" alt="FONCABSA" />
                    </router-link>
                </div>

                <div class="box-hamburger-menu" v-on:click="openMenu()">
                    <span class="hamburger-menu"></span>
                    <span class="hamburger-menu"></span>
                    <span class="hamburger-menu"></span>
                </div>
                <div class="flex justify-between items-center user-fullname-lateral-menu">
                    <div class="flex flex-col items-end mr-4">
                        <p class="nameHeader">{{ camelize(fullName) }}</p>
                        <p class="rfcHeader">R.F.C.: {{ rfc }}</p>
                    </div>
                    <img class="imgUserHeader" src="@/assets/user-ico.svg" alt="User image" />
                </div>
            </div>
        </div>
    </header>
    <!--<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">-->
    <!-- <link href="https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css" rel="stylesheet"> -->
</div>
</template>

<script>
import {
    toRef,
    ref,
    onBeforeMount
} from 'vue'
import {
    getTokenDecoden
} from "@/helpers/tokenauth";
import {
    useRouter
} from "vue-router";
import {
    useStore
} from 'vuex';

export default {
    props: {
        localNavigation: {
            type: Boolean,
            default: false
        }
    },
    setup(props, {
        emit
    }) {
        let rfc = ref("");
        let primer_nombre = ref("");
        let segundo_nombre = ref("");
        let paterno = ref("");
        let materno = ref("");
        let fullName = ref("");
        const localNavigation = toRef(props, 'localNavigation')
        const router = useRouter();
        const store = useStore();

        onBeforeMount(async () => {
            loadInfo();
            store.dispatch("setOpenMobileMenuAccountAction", false);
        });

        const goBack = () => {
            console.log("localNavigation:", localNavigation.value)
            if (!localNavigation.value) {
                const currentRoute = router.currentRoute.value.fullPath;
                if (currentRoute == "/cuenta/principal" || currentRoute == "/cuenta/cotizacion/vida-simple") return;
                router.go(-1);
            } else {
                console.log("emit goback");
                emit('go-to-back', true);
            }
        }

        function loadInfo() {
            const auth = getTokenDecoden();
            if (auth.obj.role == "member" || auth.obj.role == "cabsa_member") {
                rfc.value = auth.obj.person["rfc"];
                fullName.value = `${auth.obj.person["name"]} ${auth.obj.person["middle_name"]} ${auth.obj.person["paternal_name"]} ${auth.obj.person["maternal_name"]}`;
                primer_nombre.value = auth.obj.person["name"];
                segundo_nombre.value = auth.obj.person["middle_name"];
                paterno.value = auth.obj.person["paternal_name"];
                materno.value = auth.obj.person["maternal_name"];
            }
            if (
                auth.obj.role == "administrator" ||
                auth.obj.role == "enroller" ||
                auth.obj.role == "loan_analyst" ||
                auth.obj.role == "member_analyst" ||
                auth.obj.role == "supervisor"
            ) {
                fullName.value = `${auth.obj.personnel_info["name"]} ${auth.obj.personnel_info["middle_name"]} ${auth.obj.personnel_info["paternal_name"]} ${auth.obj.personnel_info["maternal_name"]}`;
                primer_nombre.value = auth.obj.personnel_info["name"];
                segundo_nombre.value = auth.obj.personnel_info["middle_name"];
                paterno.value = auth.obj.personnel_info["paternal_name"];
                materno.value = auth.obj.personnel_info["maternal_name"];
            }
        }

        function openMenu() {
            let windowWith = window.innerWidth;
            let open = false;

            if (windowWith >= 767) {
                open = store.getters.getOpenLateralMenuAccount;
                open = !open;
                store.dispatch("setOpenMobileMenuAccountAction", false);
                store.dispatch("setOpenLateralMenuAccountAction", open);
            } else {
                open = store.getters.getOpenMobileMenuAccount;
                open = !open;
                store.dispatch("setOpenLateralMenuAccountAction", false);
                store.dispatch("setOpenMobileMenuAccountAction", open);
            }
        }

        function camelize(str) {
            let response = "";
            if (str != "") {
                str = str
                    .toLowerCase()
                    .replace(/(?:(^.)|([-_\s]+.))/g, function (match) {
                        return match.charAt(match.length - 1).toUpperCase();
                    });
                response = str.charAt(0).toUpperCase() + str.substring(1);
                return response.replace(/([A-Z])/g, " $1").trim();
            }
            return "";
        }

        return {
            goBack,
            loadInfo,
            openMenu,
            camelize,
            rfc,
            primer_nombre,
            segundo_nombre,
            paterno,
            materno,
            fullName
        };
    }
};
</script>

<style scoped>
.header {
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #e6e6e6;
    padding: 10px 25px 10px 25px;
}

nav {
    box-shadow: 0px 4px 6px -3px rgb(178 176 176);
    -webkit-box-shadow: 0px 4px 6px -3px rgb(178 176 176);
    -moz-box-shadow: 0px 4px 6px -3px rgb(178 176 176);
}

h1 {
    margin-top: 0px !important;
}

p {
    font-family: Roboto;
    font-size: 16px;
    margin-bottom: 0px !important;
    color: #726659;
}

/* .box-logo {
  display: flex;
  align-items: center;
  width: 220px;
  height: 42px;
  padding: 13px;
}

.box-logo > img {
  width: 100%;
}

.box-hamburger-menu {
  width: 30px;
  height: 30px;
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 11px;
  cursor: pointer;
}

.hamburger-menu {
  width: 100%;
  height: 3px;
  background-color: #690b2f;
  border-radius: 12px;
  margin-bottom: 6px;
} */

.imgUserHeader {
    width: 60px;
}

.nameHeader {
    font-weight: 400;
}

.rfcHeader {
    font-weight: 300;
}

@media (max-width: 767px) {
    .user-fullname-lateral-menu {
        display: none;
    }

    .back-arrow-menu-desktop {
        display: none;
    }
}

@media (min-width: 767px) {
    nav {
        padding: 3% 6%;
    }

    .desktop {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    .back-arrow-menu-mobile,
    .box-hamburger-menu {
        display: none;
    }

    .back-arrow-menu-desktop {
        display: block;
    }
}
</style>
