<template>
  <div
    class="menu_lateral_principal"
    v-show="$store.getters.getOpenLateralMenuAccount"
  >
    <div class="row-span-2" style="">
      <div style="padding: 20px">
        <p
          style="
            font-size: 18px;
            font-family: Roboto;
            font-weight: 300;
            color: #fff;
          "
        >
          Menú
        </p>
      </div>
      <ul class="accordion-menu">
        <li>
          <img
            src="@/assets/Admin/icon-home.svg"
            width="20"
            height="20"
            style="float: left; margin-top: 15px; margin-left: 20px"
            loading="lazy"
          />
          <a href="/cuenta/principal">
            <div class="dropdownlink">
              Principal
              <i class="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </a>
        </li>
        <li>
          <img
            src="@/assets/Admin/icon-document-add.svg"
            width="20"
            height="20"
            style="float: left; margin-top: 10px; margin-left: 20px"
            loading="lazy"
          />
          <div class="dropdownlink">
            <i class="fa fa-road" aria-hidden="true"></i> Mis Datos
            <i class="fa fa-chevron-down" aria-hidden="true"></i>
          </div>
          <ul class="submenuItems">
            <li>
              <a href="/cuenta/completar-registro">Completa tu registro</a>
            </li>
            <li>
              <a href="/cuenta/carga-documentos/1">Carga de documentos</a>
            </li>
          </ul>
        </li>
        <!-- <li>
                        <img  src="@/assets/Cuenta/registro.svg" width="20" height="20" style="float:left; margin-top:15px; margin-left:10px;">
                        <a href="/cuenta/completar-registro">
                            <div class="dropdownlink">Completa tu registro
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                            </div>
                        </a>
                    </li> -->
        <li>
          <img
            src="@/assets/Cuenta/datos.svg"
            width="20"
            height="20"
            style="float: left; margin-top: 15px; margin-left: 20px"
            loading="lazy"
          />
          <a href="/cuenta/actualizar-datos-personales">
            <div class="dropdownlink">
              Actualizar datos personales
              <br />
              <i class="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </a>
        </li>
        <li>
          <img
            src="@/assets/Cuenta/contrasena.svg"
            width="20"
            height="20"
            style="float: left; margin-top: 15px; margin-left: 20px"
            loading="lazy"
          />
          <a href="/cuenta/cambiar-contrasena">
            <div class="dropdownlink">
              Cambiar contraseña
              <i class="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </a>
        </li>
        <li>
          <img
            src="@/assets/Cuenta/ahorro.svg"
            width="20"
            height="20"
            style="float: left; margin-top: 15px; margin-left: 20px"
            loading="lazy"
          />
          <a href="/cuenta/simulador">
            <div class="dropdownlink">
              Simulador de ahorro
              <br />
              <span style="font-size: 13px">Actualmente en construcción</span>
              <i class="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </a>
        </li>
        <li>
          <img
            src="@/assets/Admin/icon-home.svg"
            width="20"
            height="20"
            style="float: left; margin-top: 15px; margin-left: 20px"
            loading="lazy"
          />
          <a
            href="/captura-asistida/seccion-uno"
            id="assisted-enrollment-reedirect"
          >
            <div class="dropdownlink">
              Captura Asistida
              <i class="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </a>
        </li>
        <li style="">
          <img
            src="@/assets/Cuenta/person-plus-fill.svg"
            width="25"
            height="25"
            style="float: left; margin-top: 15px; margin-left: 20px"
            loading="lazy"
          />
          <div class="dropdownlink">
            <i class="fa fa-road" aria-hidden="true"></i> Referidos
            <i class="fa fa-chevron-down" aria-hidden="true"></i>
          </div>

          <ul class="submenuItems">
            <li style="">
              <a href="/cuenta/referidos/referir-persona">Referir Persona</a>
            </li>
            <li style="">
              <a href="/cuenta/referidos">Historial de Referidos</a>
            </li>
          </ul>
        </li>
        <!-- <li>
                        <img  src="@/assets/Cuenta/ahorro.svg" width="20" height="20" style="float:left; margin-top:5px; margin-left:20px;">
                        <div class="dropdownlink"><i class="fa fa-road" aria-hidden="true"></i> Crédito
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        </div>
                        <ul class="submenuItems">
                            <li><a href="/cuenta/prestamo/principal">Captura de Solicitud</a></li>
                        </ul>
                    </li> -->
        <li>
          <img
            src="@/assets/Admin/cerrar-sesion.svg"
            width="20"
            height="20"
            style="float: left; margin-top: 15px; margin-left: 20px"
            loading="lazy"
          />
          <a v-on:click="Logout" href="#">
            <div class="dropdownlink">
              Cerrar Sesión
              <i class="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  name: "MenuLateral",
  data() {
    return {};
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  props: {},
  beforeMount() {},
  mounted() {
    $(function () {
      var Accordion = function (el, multiple) {
        this.el = el || {};
        // more then one submenu open?
        this.multiple = multiple || false;

        var dropdownlink = this.el.find(".dropdownlink");
        dropdownlink.on(
          "click",
          { el: this.el, multiple: this.multiple },
          this.dropdown
        );
      };

      Accordion.prototype.dropdown = function (e) {
        var $el = e.data.el,
          $this = $(this),
          //this is the ul.submenuItems
          $next = $this.next();

        $next.slideToggle();
        $this.parent().toggleClass("open");

        if (!e.data.multiple) {
          //show only one menu at the same time
          $el
            .find(".submenuItems")
            .not($next)
            .slideUp()
            .parent()
            .removeClass("open");
        }
      };

      var accordion = new Accordion($(".accordion-menu"), false);
    });
  },
  methods: {
    Logout() {
      sessionStorage.clear();
      this.router.push({ path: "/" });
      // window.location.href = "/"
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

body {
  background: #388e3c;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

h2 {
  text-align: center;
  margin: 20px auto;
  color: #fff;
}

.accordion-menu {
  width: 100%;
  max-width: 350px;
  background: #810042;
  border-radius: 4px;
}

.accordion-menu li a {
  text-decoration: none;
}
.accordion-menu li:hover {
  background-color: #690b2f;
}
.submenuItems li:hover {
  background-color: #fff;
}
.accordion-menu li.open .dropdownlink {
  color: #726659;
}

.accordion-menu li.open .dropdownlink .fa-chevron-down {
  transform: rotate(180deg);
}

.accordion-menu li:last-child .dropdownlink {
  border-bottom: 0;
}
.dropdownlink {
  cursor: pointer;
  display: block;
  padding: 15px 15px 15px 45px;
  font-size: 15px;
  font-family: Roboto;
  font-weight: 300;
  color: #fff;
  position: relative;
  transition: all 0.4s ease-out;
  margin-left: 5px;
}

.dropdownlink i {
  position: absolute;
  top: 17px;
  left: 16px;
  margin-top: 10px;
}

.dropdownlink .fa-chevron-down {
  right: 12px;
  left: auto;
}

.submenuItems {
  display: none;
  background: #fff;
  width: 100%;
}

.submenuItems li {
  border-bottom: 1px solid #fff;
}

.submenuItems li a {
  text-decoration: none;
}

.submenuItems a {
  display: block;
  color: #690b2f;
  padding: 12px 12px 12px 20px;
  transition: all 0.4s ease-out;
}

.menu_lateral_principal {
  background-color: #810042;
  height: 100%;
}
/* .submenuItems a:hover {
  background-color: #FFF;
  color: #fff;
} */

@media (max-width: 767px) {
  .menu_lateral_principal {
    display: none;
  }
}
</style>