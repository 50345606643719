<template>
    <html>
      <body class="" style="background: #FFF">
          <HeaderMobil />
          <MenuLateralMobile/>
          <div class="h-screen grid grid-cols-6 grid-rows-12 grid-flow-col">
            <MenuLateral/>
            <div class="row-span-2 col-span-6" style="background:#FFF;padding:20px;">
              <SimuladorAhorro/>
            </div>
          </div>
          <a name="footer"></a>
          
      </body>
    </html>
</template>

<script>
import SimuladorAhorro from "../../components/Cuenta/SimuladorAhorro.vue"
import HeaderMobil from "../../components/Cuenta/HeaderMobil.vue"
import MenuLateralMobile from '@/components/MenuLateralMobile.vue';
import MenuLateral from '@/components/LateralMenu.vue';

export default {
  name: 'SimuladorAhorroo',
  components: {
    SimuladorAhorro,
    HeaderMobil,
    MenuLateralMobile,
    MenuLateral
  }
}
</script>

<style scoped>
.center {
  padding: 00px 0;
  border: 3px solid #ffffff;
  text-align: center;
}

@media (min-width: 766px) {
  .collapse.dont-collapse-sm {
    display: block;
    height: auto !important;
    visibility: visible;
  }
}


  #button_popups{
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        background-color: #FEB72B;
        width: 150px;
        height: 30px;
        padding-top: 0%;
    }

    button {outline: none !important;}
</style>