<template>
<div class=" mx-auto">
    <div>
        <div>
            <center>
                <div class="flex-1 bg-gray-0 px-0 py-1 m-1">
                    <div class="row">
                        <center>
                            <p class="lg:text-3xl lg:text-3xl lg:text-3xl lg:text-4xl xl:text-5xl text-cherryColor" style="font-weight: bold; margin-top:40px;">Simulador Ahorro</p>
                        </center>
                    </div>
                </div>
                <div class="flex-1 bg-gray-0 px-0 py-1 m-1">
                    <div class="row">
                        <center>
                            <p class="md:text-xl lg:text-1xl lg:text-2xl lg:text-3xl xl:text-4xl" style="color: #6E6E6E;">
                                
                            </p>
                        </center>
                    </div>
                </div>
                <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
                    <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lx:grid-cols-3">
                        <div class="row">
                            <center>
                            </center>
                        </div>
                        <div class="row">
                            <center>
                            </center>
                        </div>
                        <div class="row">
                            <center>
                            </center>
                        </div>
                    </div>
                </div>
            </center>
        </div>
    </div>
    <!--<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">-->
    <!--<link href="../../node_modules/tailwindcss/dist/tailwind.min.css" rel="stylesheet"> -->
</div>
</template>



<script>
import MenuLateral from '@/components/Cuenta/MenuLateral.vue'

export default {
    name: 'SimuladorAhorro',
    props: {
        msg: String
    },
    components: {
        MenuLateral
    },
    beforeMount() {
    },
    methods:{   
        Logout(){
            sessionStorage.clear();
            //localStorage.clear();
            window.location.href = "/"
        }
    }
}
</script>

<style>
#panel{
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
</style>